@import "variables";
@import "~bootstrap/scss/breadcrumb";

.pgn__breadcrumb {
  .list-inline-item {
    &.active {
      color: $breadcrumb-active-color;
    }

    a.link-muted {
      color: $breadcrumb-color;

      &:focus {
        position: relative;
        outline: none;
        box-shadow: none;
        text-decoration: none;
      }

      &:focus::before {
        content: "";
        position: absolute;
        top: -$breadcrumb-border-focus-axis-y;
        right: -$breadcrumb-border-focus-axis-x;
        bottom: -$breadcrumb-border-focus-axis-y;
        left: -$breadcrumb-border-focus-axis-x;
        border: $breadcrumb-border-focus-width solid $breadcrumb-color;
        border-radius: $breadcrumb-focus-border-radius;
      }
    }

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: $breadcrumb-margin-left;
    }

    .pgn__icon {
      [dir="rtl"] & {
        transform: scale(-1);
      }
    }
  }

  &-dark {
    .list-inline-item {
      &.active {
        color: $breadcrumb-inverse-active;
      }

      a.link-muted {
        color: $breadcrumb-inverse-color;

        &:focus::before {
          border: $breadcrumb-border-focus-width solid $breadcrumb-inverse-color;
        }
      }
    }

    .pgn__icon,
    .custom-spacer {
      color: $breadcrumb-inverse-spacer;
    }
  }

  ol.is-mobile {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .list-inline-item:not(:last-child) {
      margin-right: 0;
      margin-left: $breadcrumb-margin-left;
    }

    .pgn__icon {
      transform: scale(-1);

      [dir="rtl"] & {
        transform: scale(1);
      }
    }
  }
}
