@import "~bootstrap/scss/type";

@mixin mobile-type {
  .display-1,
  .display-2,
  .display-3,
  .display-4 {
    font-size: $display-mobile-size;
    line-height: $display-mobile-line-height;
  }

  h1,
  .h1 {
    font-size: $h1-mobile-font-size;
  }

  h2,
  .h2 {
    font-size: $h2-mobile-font-size;
  }

  h3,
  .h3 {
    font-size: $h3-mobile-font-size;
  }

  h4,
  .h4 {
    font-size: $h4-mobile-font-size;
  }

  h5,
  .h5 {
    font-size: $h5-mobile-font-size;
  }

  h6,
  .h6 {
    font-size: $h6-mobile-font-size;
  }
}

@media (max-width: map-get($grid-breakpoints, "sm")) {
  @include mobile-type;
}

.mobile-type {
  @include mobile-type;
}

.heading-label {
  text-transform: uppercase;
  font-family: $font-family-monospace;
  margin-bottom: 0 !important;
}

p > a[href]:not(.btn),
a.inline-link {
  color: $inline-link-color;
  text-decoration: $inline-link-decoration;
  text-decoration-line: $inline-link-decoration;
  text-decoration-color: $inline-link-decoration-color;

  &:hover {
    color: $inline-link-hover-color;
    text-decoration: $inline-link-hover-decoration;
    text-decoration-line: $inline-link-hover-decoration;
    text-decoration-color: $inline-link-hover-decoration-color;
  }
}

a.muted-link {
  color: $muted-link-color;
  text-decoration: $muted-link-decoration;

  &:hover {
    color: $muted-link-hover-color;
    text-decoration: $muted-link-hover-decoration;
  }

  p > &[href]:not(.btn),
  &.inline-link {
    color: $muted-inline-link-color;
    text-decoration: $muted-inline-link-decoration;
    text-decoration-line: $muted-inline-link-decoration;
    text-decoration-color: $muted-inline-link-decoration-color;

    &:hover {
      color: $muted-inline-link-hover-color;
      text-decoration: $muted-inline-link-hover-decoration;
      text-decoration-line: $muted-inline-link-hover-decoration;
      text-decoration-color: $muted-inline-link-hover-decoration-color;
    }
  }
}

a.brand-link {
  color: $brand-link-color;
  text-decoration: $brand-link-decoration;

  &:hover {
    color: $brand-link-hover-color;
    text-decoration: $brand-link-hover-decoration;
  }

  p > &[href]:not(.btn),
  &.inline-link {
    color: $brand-inline-link-color;
    text-decoration: $brand-inline-link-decoration;
    text-decoration-line: $brand-inline-link-decoration;
    text-decoration-color: $brand-inline-link-decoration-color;

    &:hover {
      color: $brand-inline-link-hover-color;
      text-decoration: $brand-inline-link-hover-decoration;
      text-decoration-line: $brand-inline-link-hover-decoration;
      text-decoration-color: $brand-inline-link-hover-decoration-color;
    }
  }
}
