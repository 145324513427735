@import 'style.scss';

.survey-form-panel {
  font-weight: 300;
}

.survey-form-panel .custom-h1-style {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color:       $dlai-body-color;
}

.survey-form-panel .custom-image-style {
  width: 100%;
}

.survey-form-panel .normalize-form-group-style {
  padding-left:  0px;
  padding-right: 0px;
}

.survey-form-panel .form-row-custom-h {
  height: 10%;
}

.survey-form-panel hr {
  margin-top: 32px;
  margin-bottom: 32px;
}

.survey-form-panel .custom-form-style .custom-text-input-style {

  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    border-color: $dlai-secondary-80;
    box-shadow: none;
    border-width: 2px;
  }

  &:focus {
    border-color: $dlai-secondary-80;
    box-shadow: none;
  }

  &.readOnly {
    &:hover,
    &:focus,
    &:active {
      border-color: $input-border-color;
      border-width: 1px;      
    }

    & + div label {
      border-radius: 8px;
    }
  }
}


.survey-form-panel .custom-radio-label-style {
  font-weight: 400;
}

.survey-form-panel .custom-radio-style {
  flex-shrink:  0;
  border-width: 1px;
  border-color: $input-border-color;

  &:not(:disabled):hover,
  &:not(:disabled):focus,
  &:not(:disabled):active
  {
    border-color: $dlai-secondary-80;
    box-shadow:   none;
    border-width: 2px;
  }

  &:not(:disabled):focus::before {
    content: none;
  }

}

.survey-form-panel .custom-checkbox-style {
  flex-shrink: 0;
  border-width: 1px;
  border-color: $input-border-color;

  &:not(:disabled):hover,
  &:not(:disabled):focus,
  &:not(:disabled):active
  {
    border-color: $dlai-secondary-80;
    box-shadow:   none;
    border-width: 2px;
  }

  &:not(:disabled):focus::before {
    content: none;
  }
}

.survey-form-panel .custom-checkbox-label-style {
  font-weight: 400;
  margin-left: 8px;
  color: $dlai-secondary;

}

.survey-form-panel .custome-button-style {
  width: 100%;
}


.custom-required-field-symbol-style {
  color: $dlai-tertiary-cherry-100;
}

.custom-field-bullet-symbol {
  color: $dlai-border-color;
}