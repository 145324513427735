$icon-button-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "brand": $brand,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "black": $black,
) !default;
$btn-icon-diameter-md: 2.75rem !default;
$btn-icon-diameter-sm: 2.25rem !default;
$btn-icon-diameter-inline: calc(#{$line-height-base}em + .1em) !default;

$btn-icon-bg: transparent !default;
$btn-icon-accent-color: white !default;

@mixin btn-icon-size($icon-size) {
  width: $icon-size;
  height: $icon-size;
}

@mixin btn-icon-variant($icon-color, $accent-color, $active-state: false) {
  &.hover,
  &:hover,
  &.active,
  &:active {
    background-color: $icon-color;
    color: $accent-color;
  }

  @if $active-state {
    background-color: $icon-color;
    color: $accent-color;

    &.focus,
    &:focus {
      outline: 2px transparent;
      background-color: $icon-color;
      color: $accent-color;
    }
  } @else {
    background-color: $btn-icon-bg;
    color: $icon-color;

    &.focus,
    &:focus {
      outline: 2px transparent;
      background-color: $btn-icon-bg;
      color: $icon-color;
      box-shadow: inset 0 0 0 $btn-focus-width $icon-color;
    }
  }
}

.btn-icon {
  @include btn-icon-size($btn-icon-diameter-md);

  border-radius: 50%;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  border: none;

  &.btn-icon-sm {
    @include btn-icon-size($btn-icon-diameter-sm);
  }

  &.btn-icon-inline {
    @include btn-icon-size($btn-icon-diameter-inline);

    padding: 1em;
    vertical-align: middle;

    .btn-icon__icon-container {
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .btn-icon__icon-container {
    display: inline-flex;
    align-self: center;

    .pgn__icon.btn-icon__icon {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .btn-icon__icon {
    height: 1em;
    width: 1em;
  }

  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    pointer-events: none;
  }

  @each $name, $color in $icon-button-colors {
    &.btn-icon-#{$name} {
      @include btn-icon-variant($color, $btn-icon-accent-color);
    }

    &.btn-icon-inverse-#{$name} {
      @include btn-icon-variant($btn-icon-accent-color, $color);
    }

    &.btn-icon-#{$name}-active {
      @include btn-icon-variant($color, $btn-icon-accent-color, true);
    }

    &.btn-icon-inverse-#{$name}-active {
      @include btn-icon-variant($btn-icon-accent-color, $color, true);
    }
  }
}
