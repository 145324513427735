.paragon-fieldset {
  margin-bottom: $spacer * 1.5;

  .form-control {
    height: auto;
  }

  fieldset legend {
    width: auto;
    margin-bottom: 0;
  }
}
