@import "variables";

//
// Override Bootstrap's alert definition.
// We do this to get more control over colors
// used in alerts.
//

//
// Base styles
//

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;

  @include border-radius($alert-border-radius);
  @include pgn-box-shadow(1, "down");

  .alert-message-content > :last-child {
    margin-bottom: 0;
  }

  .alert-icon {
    float: left;
    margin-inline-end: $alert-icon-space;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }
}

// Headings for larger alerts
.alert-heading {
  // Specified to prevent conflicts of changing $headings-color
  color: $alert-title-color;
  display: flex;
}

// added to align content and icon
.alert-content {
  display: flex;
  font-size: $alert-font-size;
  line-height: $alert-line-height;
  color: $alert-content-color;
  text-align: start;

  div {
    flex-grow: 1;
  }
}

// Provide class for links that match alerts
.alert-link {
  font-weight: $alert-link-font-weight;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

// Baking in $close-font-size: $font-size-base * 1.5 !default; to avoid any dependency
.alert-dismissible {
  padding-right: ($font-size-base * 1.5) + $alert-padding-x * 2;

  // Adjust close link position
  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: $alert-padding-y $alert-padding-x;
    color: inherit;
  }
}

.pgn__alert-message-wrapper {
  display: flex;
  align-items: center;

  .pgn__alert-actions {
    margin-inline-start: $alert-actions-gap;
  }
}

.pgn__alert-message-wrapper-stacked {
  .pgn__alert-actions {
    margin-top: $alert-actions-gap;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for coloring the alert.

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    color: inherit;
    background-color: theme-color($color, "background");
    border-color: theme-color($color, "border");

    .alert-icon {
      color: theme-color($color, "default");
    }
  }
}
