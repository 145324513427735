$search-btn-variants: (
  "light":  $primary-500,
  "dark":   $brand-500,
);
$search-border-radius:                0 !default;
$search-line-height:                  1.5rem !default;
$search-border-color:                 $gray-500 !default;
$search-border-color-interaction:     $black !default;
$search-border-width:                 .0625rem !default;
$search-border-width-interaction:     .125rem !default;
$search-disabled-opacity:             .3 !default;
$search-button-margin:                .5rem !default;
$input-height-search:                 calc(#{$input-line-height * 1em} + #{$input-padding-y * 2}) !default;
$search-border-focus-color:           $black !default;
$search-border-focus-width:           .3125rem !default;
