$avatar-border-radius:               100% !default;
$avatar-border:                      solid 1px $light-300 !default;

$avatar-size-xs:                     1.5rem !default;
$avatar-size-sm:                     2.25rem !default;
$avatar-size:                        3rem !default;
$avatar-size-lg:                     4rem !default;
$avatar-size-xl:                     6rem !default;
$avatar-size-xxl:                    11.5rem !default;
$avatar-size-huge:                   18.75rem !default;
