@import "variables";
@import "~bootstrap/scss/tables";

.btn-header {
  padding: 0;
  font-weight: $font-weight-bold;
}

.table th,
.table td {
  word-wrap: break-word;
}
