@import "variables";

@function get-color($element, $color, $type) {
  @return map-get($chip-theme-colors, "#{$color}-#{$element}-#{$type}");
}

@mixin chip-variant($background, $border, $hover-background,
$hover-border, $active-background, $active-border, $color,
$hover-color, $active-color, $focus-border) {
  background-color: $background;
  color: $color;
  border-color: $border;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: $hover-background;
    color: $hover-color;
    border-color: $hover-border;
  }

  &.active,
  &:active {
    background-color: $active-background;
    color: $active-color;
    border-color: $active-border;
  }

  &:focus::before {
    content: "";
    position: absolute;
    top: -$chip-position-axis;
    right: -$chip-position-axis;
    bottom: -$chip-position-axis;
    left: -$chip-position-axis;
    border: $chip-border-width solid $focus-border;
    border-radius: $chip-focus-border-radius;
  }

  &:disabled {
    cursor: default;
    background-color: $background;
    color: $color;
    border-color: $border;
    opacity: $chip-disable-opacity;
    pointer-events: none;

    &::before {
      display: none;
    }
  }
}

.pgn__chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $chip-padding-y $chip-padding-x;
  font-size: $chip-font-size;
  font-weight: $chip-font-weight;
  line-height: $chip-line-height;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: $chip-border-radius;
  border: $chip-border-width solid transparent;
  margin-inline: calc($chip-margin-inline / 2);

  &:focus {
    position: relative;
    outline: none;
    box-shadow: none;
  }

  // Empty chip collapse automatically
  &:empty {
    display: none;
  }

  .chip-icon-before {
    margin-inline-end: .5rem;
    margin-left: -.25em;

    [dir="rtl"] & {
      margin-right: -.25em;
      margin-left: .5rem;
    }
  }

  .chip-icon-after {
    margin-inline-start: .5rem;
    margin-right: -.25em;

    [dir="rtl"] & {
      margin-right: .5rem;
      margin-left: -.25em;
    }
  }
}

@each $color in $chip-theme-variants {
  .pgn__chip-#{$color} {

    $background: get-color("background", $color, "inactive");
    $border: get-color("border", $color, "inactive");
    $text: get-color("text", $color, "inactive");

    $hover-background: get-color("background", $color, "hover");
    $hover-border: get-color("border", $color, "hover");
    $hover-text: get-color("text", $color, "hover");

    $active-background: get-color("background", $color, "selected");
    $active-border: get-color("border", $color, "selected");
    $active-text: get-color("text", $color, "selected");

    $focus-border: get-color("border", $color, "focus");

    @include chip-variant(
      $background,
      $border,
      $hover-background,
      $hover-border,
      $active-background,
      $active-border,
      $text,
      $hover-text,
      $active-text,
      $focus-border
    );
  }
}
