@import "variables";

$checkpoint-arrow-top-color: solid $checkpoint-arrow-width $checkpoint-border-color;
$checkpoint-arrow-default-color: solid $checkpoint-arrow-width $checkpoint-background-color;
$checkpoint-arrow-transparent: solid $checkpoint-arrow-width transparent;

.pgn__checkpoint {
  position: absolute;
  background: $checkpoint-background-color;
  border-top: $checkpoint-border-width solid $checkpoint-border-color;
  border-radius: $border-radius;
  padding: map_get($spacers, 4);
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .3);
  z-index: $checkpoint-z-index;
  max-width: $checkpoint-max-width;

  @media (max-width: map-get($grid-breakpoints, "md")) {
    min-width: 90%;
    max-width: 90%;
  }

  .pgn__checkpoint-action-row {
    display: flex;
    justify-content: flex-end;
  }

  #pgn__checkpoint-arrow,
  #pgn__checkpoint-arrow::before,
  #pgn__checkpoint-arrow::after {
    position: absolute;
    width: 0;
    height: 0;
  }

  #pgn__checkpoint-arrow {
    visibility: hidden;
  }

  #pgn__checkpoint-arrow::before,
  #pgn__checkpoint-arrow::after {
    visibility: visible;
    content: "";
  }

  .pgn__checkpoint-button_dismiss {
    margin-inline-end: map_get($spacers, 2);
  }

  .pgn__checkpoint-breadcrumb {
    height: 6px;
    width: 6px;
    border-radius: 50%;

    &.pgn__checkpoint-breadcrumb_active {
      background: $checkpoint-breadcrumb-color;
    }

    &.pgn__checkpoint-breadcrumb_inactive {
      border: 1px solid $checkpoint-breadcrumb-color;
      background: transparent;
    }

    &:not(:first-child) {
      margin-left: map_get($spacers, 1\.5);
    }

    [dir="rtl"] & {
      margin-left: map_get($spacers, 1\.5);
      margin-right: 0;

      &:last-child {
        margin-left: 0;
      }
    }
  }

  .pgn__checkpoint-breadcrumb-container {
    display: flex;
    align-items: center;
  }

  .pgn__checkpoint-body {
    color: $checkpoint-body-color;
    margin-bottom: map_get($spacers, 3\.5);
    text-align: start;
  }

  .pgn__checkpoint-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: map_get($spacers, 2\.5);
  }

  #pgn__checkpoint-title {
    font-size: $h3-font-size;
    margin-inline-end: map_get($spacers, 2\.5);
    margin-bottom: 0;
  }
}

.pgn__checkpoint[data-popper-placement^="top"] > #pgn__checkpoint-arrow {
  left: -$checkpoint-arrow-width !important; // Override PopperJS arrow placement
  bottom: 1px;

  [dir="rtl"] & {
    left: $checkpoint-arrow-width !important;
  }

  &::after {
    border-bottom: $checkpoint-arrow-transparent;
    border-top: $checkpoint-arrow-default-color;
    border-left: $checkpoint-arrow-transparent;
    border-right: $checkpoint-arrow-transparent;
    filter: drop-shadow(0 4px 2px rgba(0, 0, 0, .1));
    -webkit-filter: drop-shadow(0 4px 2px rgba(0, 0, 0, .1));
  }
}

.pgn__checkpoint[data-popper-placement^="bottom"] > #pgn__checkpoint-arrow {
  top: -36px;
  left: -$checkpoint-arrow-width !important; // Override PopperJS arrow placement

  &::after {
    border-bottom: $checkpoint-arrow-top-color;
    border-top: $checkpoint-arrow-transparent;
    border-left: $checkpoint-arrow-transparent;
    border-right: $checkpoint-arrow-transparent;
  }
}

.pgn__checkpoint[data-popper-placement^="left"] > #pgn__checkpoint-arrow {
  top: -$checkpoint-arrow-width !important; // Override PopperJS arrow placement
  right: 1px;

  &::after {
    border-bottom: $checkpoint-arrow-transparent;
    border-top: $checkpoint-arrow-transparent;
    border-left: $checkpoint-arrow-default-color;
    border-right: $checkpoint-arrow-transparent;
    filter: drop-shadow(3px 1px 2px rgba(0, 0, 0, .1));
  }
}

.pgn__checkpoint[data-popper-placement^="right"] > #pgn__checkpoint-arrow {
  top: -$checkpoint-arrow-width !important; // Override PopperJS arrow placement
  left: 1px;

  &::after {
    left: -2 * $checkpoint-arrow-width;
    border-bottom: $checkpoint-arrow-transparent;
    border-top: $checkpoint-arrow-transparent;
    border-left: $checkpoint-arrow-transparent;
    border-right: $checkpoint-arrow-default-color;
    filter: drop-shadow(-3px 1px 2px rgba(0, 0, 0, .1));
  }
}
