@import "variables";
@import "~bootstrap/scss/card";

.pgn__card,
.pgn__card-body {
  width: 100%;
}

.pgn__card-grid {
  .row > div[class*="col-"] {
    margin-bottom: $card-grid-margin;
    display: flex;
    flex: 1 0 auto;
  }
}

.pgn__card-header {
  padding: 0 $card-spacer-x;
  display: flex;
  justify-content: space-between;

  .pgn__card-header-content {
    display: flex;
    flex-direction: column;
    margin-top: map_get($spacers, 4\.5);
    overflow: auto;
    text-align: start;
    width: 100%;
  }

  %header-title {
    color: $black;
    font-weight: $font-weight-bold;
    text-align: start;
  }

  .pgn__card-header-title,
  .pgn__card-header-title-sm,
  .pgn__card-header-title-md {
    @extend %header-title;
  }

  .pgn__card-header-title-sm {
    font-size: $h4-font-size;
  }

  .pgn__card-header-title-md {
    font-size: $h3-font-size;
  }

  %header-subtitle {
    color: $gray-700;
    margin-top: map_get($spacers, 1);
  }

  .pgn__card-header-subtitle,
  .pgn__card-header-subtitle-sm,
  .pgn__card-header-subtitle-md {
    @extend %header-subtitle;
  }

  .pgn__card-header-subtitle-sm {
    font-size: $h5-font-size;
  }

  .pgn__card-header-subtitle-md {
    font-size: $h4-font-size;
  }

  .pgn__card-header-actions {
    margin-top: $spacer;
    margin-inline-start: $spacer;
    flex-shrink: 0;
  }
}

.pgn__card-divider {
  border-top: 1px solid $card-divider-bg;
  height: 0;
}

.pgn__card-section {
  padding: $card-spacer-x;
  flex-grow: 1;
  text-align: start;

  &.is-muted {
    background-color: $light-200;
  }

  .pgn__card-section-title {
    color: $black;
    font-weight: $font-weight-bold;
    font-size: $h5-font-size;
    margin-bottom: $card-spacer-y;
  }

  .pgn__card-section-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: $card-spacer-y;
  }

  dd {
    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 40px;
    }
  }
}

.pgn__card-footer {
  width: 100%;
  padding: $card-spacer-x;
  display: flex;
  align-items: center;
  justify-content: center;

  &.vertical {
    justify-content: flex-end;
    padding-top: 0;

    & > * + * {
      margin-inline-start: $card-footer-actions-gap;
    }
  }

  &.vertical-stacked {
    flex-direction: column-reverse;
    padding-top: $card-spacer-x - $card-footer-actions-gap;

    & > * {
      width: 100%;
      margin-top: $card-footer-actions-gap;
    }
  }

  &.horizontal {
    flex-wrap: wrap;

    & > :not(:last-child) {
      margin-inline-end: $card-footer-actions-gap;
    }
  }

  &.horizontal-stacked {
    flex-direction: column-reverse;
    padding-top: $card-spacer-x - $card-footer-actions-gap;

    & > * {
      margin-top: $card-footer-actions-gap;
    }
  }
}

.pgn__card-footer-text {
  color: $gray-700;
  order: 1;
  font-size: $card-footer-text-font-size;

  &.vertical {
    margin-inline-end: auto;
    order: -1;
  }

  &.vertical-stacked {
    margin-right: auto;
  }

  &.horizontal {
    margin-top: $card-spacer-x;
    width: 100%;
    text-align: center;
  }

  &.horizontal-stacked {
    margin-top: $card-spacer-x;
    width: 100%;
    text-align: center;
    order: -1;
  }
}

.pgn__card-wrapper-image-cap {
  position: relative;

  &.horizontal {
    max-width: $card-image-horizontal-max-width;

    .pgn__card-image-cap {
      height: 100%;
      border-top-left-radius: $card-image-border-radius;
      border-bottom-left-radius: $card-image-border-radius;
      border-top-right-radius: 0;
      max-width: inherit;
      width: auto;
      object-fit: cover;
    }

    .pgn__card-logo-cap {
      inset-inline-start: $card-logo-left-offset-horizontal;
      bottom: $card-logo-bottom-offset-horizontal;
    }
  }

  &.vertical {
    max-height: $card-image-vertical-max-height;
  }
}

.pgn__card-image-cap {
  object-fit: cover;
  max-height: inherit;
  width: 100%;
  border-top-left-radius: $card-image-border-radius;
  border-top-right-radius: $card-image-border-radius;
}

.pgn__card-logo-cap {
  position: absolute;
  inset-inline-start: $card-logo-left-offset;
  bottom: #{-$card-logo-bottom-offset};
  width: $card-logo-width;
  height: $card-logo-height;
  border-radius: $card-logo-border-radius;
  box-shadow: $level-1-box-shadow;
  z-index: 1;
  padding: map_get($spacers, 2);
  background-color: $white;
}

.pgn__card {
  @include pgn-box-shadow(1, "down");

  &.clickable {
    &:hover {
      cursor: pointer;

      @include pgn-box-shadow(2, "down");
    }

    &:focus,
    &.focus {
      box-shadow: $level-1-box-shadow;
      outline: none;
      border-color: $card-border-focus-color;
    }
  }

  &.horizontal {
    flex-direction: row;
  }
}

@media (max-width: map-get($grid-breakpoints, "sm")) {
  .pgn__card-footer {
    &.horizontal > :not(:last-child) {
      margin-inline-end: 0;
    }

    .btn {
      width: 100%;
      margin: .625rem 0 0;
      margin-inline-end: 0;
    }
  }

  .pgn__action-row {
    .btn:not(:last-child) {
      margin-bottom: .3125rem;
    }

    & > * + * {
      margin-inline-start: 0;
    }
  }

  .pgn__action-row-stacked,
  .pgn__card-section {
    .btn {
      width: 100%;
    }
  }
}
