@import "variables";

.toast-container {
  bottom: $toast-container-gutter-lg;
  left: $toast-container-gutter-lg;
  position: fixed;
  z-index: 2;

  [dir="rtl"] & {
    right: $toast-container-gutter-lg;
    left: 0;
  }

  @media only screen and (max-width: 768px) {
    bottom: $toast-container-gutter-sm;
    right: $toast-container-gutter-sm;
    left: $toast-container-gutter-sm;

    [dir="rtl"] & {
      left: $toast-container-gutter-sm;
      right: $toast-container-gutter-sm;
    }
  }
}
