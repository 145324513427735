@import 'style.scss';

.custom-h2-style {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: $body-color;
}

svg.custom-success-svg-style {
  fill: $brand;
  width: 100%;
  height: 100%;
}

svg.custom-warning-svg-style {
  fill: $dlai-tertiary-yellow-49;
  width: 100%;
  height: 100%;
}

.custom-des-style {
  font-family: 'Open Sans', sans-serif;
  color: $body-color;
  font-weight: 300;
  font-size: 0.75em;
  white-space: pre-wrap;
}