// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

:export {
  xs: map-get($grid-breakpoints, "xs");
  sm: map-get($grid-breakpoints, "sm");
  md: map-get($grid-breakpoints, "md");
  lg: map-get($grid-breakpoints, "lg");
  xl: map-get($grid-breakpoints, "xl");
  xxl: map-get($grid-breakpoints, "xxl");
}
