// stylelint-disable-next-line selector-class-pattern
.pgn__pageBanner-component {
  width: 100%;
  min-height: 36px;
  display: flex;
  flex-wrap: nowrap;
  font-size: $x-small-font-size;

  @include media-breakpoint-up(md) {
    font-size: $small-font-size;
  }
}

// stylelint-disable-next-line selector-class-pattern
.pgn__pageBanner__dark {
  background-color: $dark-500;
  color: white;
}

// stylelint-disable-next-line selector-class-pattern
.pgn__pageBanner__light {
  background-color: $light-400;
  color: black;
}

// stylelint-disable-next-line selector-class-pattern
.pgn__pageBanner__accentA {
  background-color: $accent-a;
  color: black;
}

// stylelint-disable-next-line selector-class-pattern
.pgn__pageBanner__warning {
  background-color: $warning-100;
  color: black;
}

// stylelint-disable-next-line selector-class-pattern
.pgn__pageBanner-content {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: map_get($spacers, 2) map_get($spacers, 2\.5);
  text-align: center;
}

// stylelint-disable-next-line selector-class-pattern
.pgn__pageBanner-dismissButtonContainer {
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  align-self: center;

  @include media-breakpoint-up(md) {
    min-width: 36px;
    min-height: 36px;
  }
}
