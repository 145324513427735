@import "~bootstrap/scss/grid";

.container-mw-xs {
  max-width: $max-width-xs + $grid-gutter-width !important;
}

.container-mw-sm {
  max-width: $max-width-sm + $grid-gutter-width !important;
}

.container-mw-md {
  max-width: $max-width-md + $grid-gutter-width !important;
}

.container-mw-lg {
  max-width: $max-width-lg + $grid-gutter-width !important;
}

.container-mw-xl {
  max-width: $max-width-xl + $grid-gutter-width !important;
}
