// Code

$code-font-size:                    87.5% !default;
$code-color:                        #E83E8C !default;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

// HTML Keyboard Input element (<kbd>) styles
$kbd-padding-y:                     .2rem !default;
$kbd-padding-x:                     .4rem !default;
$kbd-font-size:                     $code-font-size !default;
$kbd-color:                         $white !default;
$kbd-bg:                            theme-color("gray", "hover") !default;

$pre-color:                         theme-color("gray", "dark-text") !default;
$pre-scrollable-max-height:         340px !default;
