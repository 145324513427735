@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

/* 
  Usage:

    font-family: 'Open Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
*/

html, body, #root {
  font-family: 'Open Sans', sans-serif;
}

$dlai-primary: #F65B66; 
/* 53% */
$dlai-primary-20: #FDBFC7;
$dlai-primary-30: #FFA6B2;
$dlai-primary-40: #FF8B9C;
$dlai-primary-70: #FF325A;
$dlai-primary-80: #FF0043;

$dlai-secondary: #237B94;
/* 76% */
$dlai-secondary-35: #164C59;
$dlai-secondary-45: #1C6272;
$dlai-secondary-65: #288EA5;
$dlai-secondary-75: #2EA4BF;
$dlai-secondary-80: #32AFCC;

$dlai-tertiary-blue: #1C74EB;
/* 87% */
$dlai-tertiary-blue-37: #94BDEA;
$dlai-tertiary-blue-47: #7DB1EA;
$dlai-tertiary-blue-67: #4E98EA;
$dlai-tertiary-blue-77: #368CEA;
$dlai-tertiary-blue-100: #0070EA;

$dlai-tertiary-yellow: #FAB901;
/* 99% */
$dlai-tertiary-yellow-29: #FAD888;
$dlai-tertiary-yellow-39: #FCD172;
$dlai-tertiary-yellow-49: #FDC952;
$dlai-tertiary-yellow-69: #FFC128;
$dlai-tertiary-yellow-79: #FFB900;


$dlai-tertiary-purple: #A12F9D;
/* 70% */
$dlai-tertiary-purple-40: #9D589C;
$dlai-tertiary-purple-50: #9F469C;
$dlai-tertiary-purple-70: #9E1E9D;
$dlai-tertiary-purple-80: #9E009D;
$dlai-tertiary-purple-100: #9C009E;

$dlai-tertiary-cherry: #DD3C66;
/* 72% */
$dlai-tertiary-cherry-15: #DBBAC5;
$dlai-tertiary-cherry-40: #DB839F;
$dlai-tertiary-cherry-62: #DB537D;
$dlai-tertiary-cherry-85: #DB215B;
$dlai-tertiary-cherry-100: #DB0044;

$dlai-body-color: #253238;
$dlai-border-color: #cbd6e2;

$brand:                $dlai-primary;
$body-color:           $dlai-body-color;

$input-border-color:   $dlai-border-color;
$input-border-width:   1px;
$input-border-radius:  2px;

$custom-control-indicator-checked-color: $dlai-secondary-75;
$custom-control-indicator-checked-bg: $dlai-secondary-75;

@import 'normalize.css';
@import '@edx/paragon/scss/core/core.scss';