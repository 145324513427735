// Image thumbnails

$thumbnail-padding:                 .25rem !default;
$thumbnail-bg:                      $body-bg !default;
$thumbnail-border-width:            $border-width !default;
$thumbnail-border-color:            theme-color("gray", "border") !default;
$thumbnail-border-radius:           $border-radius !default;
$thumbnail-box-shadow:              0 1px 2px rgba($black, .075) !default;

// Figures

$figure-caption-font-size:          90% !default;
$figure-caption-color:              theme-color("gray", "light-text") !default;
