$data-table-background-color: $white !default;
$data-table-border: 1px solid $gray-200 !default;
$data-table-box-shadow: $box-shadow-sm !default;
$data-table-padding-x: .75rem !default;
$data-table-padding-y: .75rem !default;
$data-table-padding-small: .5rem !default;
$data-table-cell-padding: .5rem .75rem !default;
$data-table-footer-position: center !default;
$data-table-pagination-dropdown-max-height: 60vh !default;
$data-table-pagination-dropdown-min-width: 6rem !default;

.pgn__data-table-wrapper {
  font-size: $font-size-sm;
  border-radius: $border-radius;
  background-color: $data-table-background-color;
  box-shadow: $data-table-box-shadow;

  &.hide-shadow {
    box-shadow: none;
  }

  > :first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  > :last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.pgn__bulk-actions,
.pgn__table-actions {
  display: flex;

  .pgn__datatable__visible-actions {
    margin-inline-start: map_get($spacers, 2);

    .btn {
      margin-inline-start: map_get($spacers, 2);

      &:first-child {
        margin-inline-start: 0;
      }
    }
  }
}

.pgn__data-table-dataview-toggle {
  flex: auto;
}

.pgn__data-table-actions-right {
  display: flex;
  align-items: center;
}

.pgn__data-table-actions-right-toggle-bottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  width: 100%;

  .pgn__data-table-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: map_get($spacers, 4);
  }

  .pgn__icon-button-toggle__container {
    justify-content: flex-end;
  }
}

.pgn__data-table-container {
  width: 100%;
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;

  &.is-loading::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white, .7);
    z-index: 1;
  }
}

.pgn__data-table-layout-wrapper {
  display: flex;
  align-items: flex-start;

  .pgn__data-table-layout-sidebar {
    background-color: $data-table-background-color;
    border-radius: $border-radius;
    box-shadow: $data-table-box-shadow;
    padding: $data-table-padding-small;
    margin-right: map_get($spacers, 4);
    flex-grow: 0;
  }

  .pgn__data-table-side-filters {
    .pgn__data-table-side-filters-title {
      margin-bottom: map_get($spacers, 3);
      text-align: start;
    }

    .pgn__data-table-side-filters-status {
      margin-bottom: map_get($spacers, 2);
      display: flex;
      justify-content: flex-end;
    }
  }

  .pgn__data-table-layout-main {
    flex-grow: 1;
  }
}

.pgn__data-table {
  width: 100%;

  &.is-striped tr:nth-child(even) {
    background-color: $light-200;
  }

  th {
    background-color: $light-300;
    padding: $data-table-cell-padding;
    text-align: start;
  }

  td {
    padding: $table-cell-padding;
    line-height: 24px;
    text-align: start;
  }

  & tr.is-selected {
    background-color: $info-100 !important;
  }
}

.pgn__data-table-cell-wrap {
  max-width: 20vw;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pgn__data-table-empty {
  padding: $data-table-padding-x $data-table-padding-y;
}

.pgn__data-table-actions {
  display: flex;
  justify-content: space-between;
  padding-bottom: $data-table-padding-small;
}

.pgn__data-table-status-bar {
  padding: $data-table-padding-y $data-table-padding-x;
}

.pgn__data-table-filters {
  display: flex;
}

.pgn__data-table-filters-breakout-filter {
  align-self: flex-end;
  margin-inline-end: map_get($spacers, 2);
}

.pgn__data-table-filters-dropdown-item {
  padding: $data-table-padding-small 14px;
  min-width: 15em;
}

.pgn__data-table-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pgn__data-table-footer {
  display: flex;
  justify-content: space-between;
  padding: $data-table-padding-x $data-table-padding-y;
  align-items: center;
  border-top: $data-table-border;

  .btn-icon .pgn__icon {
    [dir="rtl"] & {
      transform: scale(-1);
    }
  }
}

.pgn__data-table-pagination {
  display: flex;

  .pgn__page-count {
    align-self: $data-table-footer-position;
  }
}

.pgn__data-table-pagination-dropdown {
  overflow-y: auto;
  max-height: $data-table-pagination-dropdown-max-height;
  min-width: $data-table-pagination-dropdown-min-width;

  a {
    text-align: center;
  }
}

.pgn__checkbox-filter-label {
  font-weight: $font-weight-bold;
}

.pgn__checkbox-filter {
  display: flex;

  input .form-check-input {
    margin-top: .36rem;
  }

  .form-check {
    [dir="rtl"] & {
      padding-left: 0;
      padding-right: 1.25rem;
    }

    .form-check-input {
      [dir="rtl"] & {
        margin-left: 0;
        margin-right: -1.25rem;
      }
    }
  }
}

.pgn__dropdown-filter-checkbox-group {
  margin-left: .75rem;
  margin-right: .75rem;

  input .form-check-input {
    margin-top: .36rem;
  }
}

.pgn__data-table-card-view {
  padding: 0 $data-table-padding-x;
}

.pgn__data-table__action-btn {
  margin-left: map_get($spacers, 2);

  &:first-child {
    margin-left: 0;
  }
}

.pgn__data-table-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.pgn__datatable__overflow-actions-menu {
  background: #FFFFFF;
  padding: map_get($spacers, 2);
  box-shadow: $level-1-box-shadow;
  border-radius: 4px;
}
