@import "variables";

.pgn__icon {
  display: block;
  height: 1.5rem; // 24px, Assumes base font size to be 16px
  position: relative;
  width: 1.5rem; // 24px, Assumes base font size to be 16px
  flex-shrink: 0;

  &.pgn__icon__inline {
    width: $icon-inline;
    height: $icon-inline;
  }

  &.pgn__icon__sm {
    width: $icon-sm;
    height: $icon-sm;
  }

  &.pgn__icon__md {
    width: $icon-md;
    height: $icon-md;
  }

  &.pgn__icon__lg {
    width: $icon-lg;
    height: $icon-lg;
  }
}

.pgn__icon svg {
  display: block;
  height: 100%;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}
