// Toasts

$toast-max-width:                   400px !default;
$toast-padding-x:                   .75rem !default;
$toast-padding-y:                   .25rem !default;
$toast-font-size:                   .875rem !default;
$toast-color:                       null !default;
$toast-background-color:            $gray-700 !default;
$toast-border-width:                1px !default;
$toast-border-color:                rgba(0, 0, 0, .1) !default;
$toast-border-radius:               .25rem !default;
$toast-box-shadow:                  0 1.25rem 2.5rem rgba($black, .15), 0 .5rem 3rem rgba($black, .15) !default;

$toast-header-color:                $white !default;
$toast-header-background-color:     $gray-700 !default;
$toast-header-border-color:         rgba(0, 0, 0, .5) !default;

$toast-container-gutter-lg:         1.25rem !default;
$toast-container-gutter-sm:         .625rem !default;
