@import "variables";

.btn.pgn__avatar-button-avatar {
  padding-left: $avatar-button-padding-left;

  &.pgn__avatar-button-avatar-sm {
    padding-left: $avatar-button-padding-left-sm;
  }

  &.pgn__avatar-button-avatar-lg {
    padding-left: $avatar-button-padding-left-lg;
  }

  .pgn__avatar {
    margin-top: -.5em;
    margin-bottom: -.5em;
    margin-inline-end: .5em;
  }

  &.pgn__avatar-button-hide-label {
    padding-left: 4px;

    &:not(.dropdown-toggle) {
      padding-right: 4px;
    }

    .pgn__avatar {
      margin-inline-end: 0;
    }
  }
}
