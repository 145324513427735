.pgn__scrollable-body {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;

  // this pseudo element shows the shadow on the top edge whenever the sentinel div is hidden
  &::before {
    content: "";
    background-color: transparent;
    box-shadow: 5px 0 7px 2px rgba(0, 0, 0, .55);
    display: block;
    height: 2px;
    position: sticky;
    top: -.16rem;
    z-index: 2;
  }

  // this pseudo element shows the shadow on the bottom edge whenever the sentinel div is hidden
  &::after {
    content: "";
    background-color: transparent;
    box-shadow: 5px 0 7px 2px rgba(0, 0, 0, .55);
    display: block;
    height: 2px;
    position: sticky;
    bottom: -2px;
    margin-top: 1.6rem;
  }

  &.pgn__scrollable-body-scroll-bottom {
    &::before {
      opacity: .5;
    }

    &::after {
      opacity: 0;
    }
  }

  &.pgn__scrollable-body-scroll-top {
    &::before {
      opacity: 0;
      z-index: 0;
    }

    &::after {
      opacity: .5;
    }
  }

  &.pgn__scrollable-body-scroll-top.pgn__scrollable-body-scroll-bottom {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 0;
    }
  }

  .pgn__scrollable-body-content {
    p {
      text-align: start;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .pgn__scrollable.pgn__scrollable-scroll-fullscreen & {
    box-shadow: none;
  }
}
