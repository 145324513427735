// Tooltips

$tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 200px !default;
$tooltip-color:                     $white !default;
$tooltip-bg:                        $black !default;
$tooltip-border-radius:             $border-radius !default;
$tooltip-opacity:                   1 !default;
$tooltip-padding-y:                 .5rem !default;
$tooltip-padding-x:                 .5rem !default;
$tooltip-margin:                    0 !default;
$tooltip-box-shadow:                drop-shadow(0 2px 4px rgba(0, 0, 0, .15))
  drop-shadow(0 2px 8px rgba(0, 0, 0, .15)) !default;

$tooltip-arrow-width:               .8rem !default;
$tooltip-arrow-height:              .4rem !default;
$tooltip-arrow-color:               $tooltip-bg !default;

$tooltip-color-light:               $black !default;
$tooltip-bg-light:                  $white !default;
$tooltip-arrow-color-light:         $white !default;
