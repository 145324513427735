// Checkpoints

$checkpoint-background-color:       $light-300 !default;

$checkpoint-body-color:             $gray-700;

$checkpoint-border-color:           $brand !default;
$checkpoint-border-width:           8px !default;

$checkpoint-breadcrumb-color:       $primary !default;

$checkpoint-arrow-width:            15px !default;
$checkpoint-max-width:              480px !default;

$checkpoint-z-index:                1060 !default;
