@import "variables";
@import "~bootstrap/scss/dropdown";

.dropdown-toggle::after {
  border: 0;
  border-style: solid;
  border-width: .15rem .15rem 0 0;
  content: "";
  height: .45rem;
  margin-inline-start: .5em;
  position: relative;
  top: -.05rem;
  transform: rotate(135deg);
  width: .45rem;
}

[dir="rtl"] .dropdown-item {
  text-align: right;
}

// this is a helper class to remove arrow element from Dropdown.Toggle
// pass it as bsPrefix prop for Dropdown.Toggle component when you want to render
// toggle as IconButton (that way it will override underlying react-bootstrap class)
.pgn__dropdown-toggle-iconbutton {
  white-space: nowrap;
}

.pgn__dropdown-item {
  display: flex;
  align-items: center;

  .pgn__dropdown-item__icon-before {
    margin-inline-end: .5rem;
    margin-left: -.25em;

    [dir="rtl"] & {
      margin-right: -.25em;
      margin-left: .5rem;
    }
  }

  .pgn__dropdown-item__icon-after {
    margin-inline-start: .5rem;
    margin-right: -.25em;

    [dir="rtl"] & {
      margin-right: .5rem;
      margin-left: -.25em;
    }
  }
}
