@import "variables";
@import "~bootstrap/scss/modal";
@import "ModalDialog";

.pgn__modal-layer {
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $zindex-modal;
  -webkit-overflow-scrolling: touch;
}

.pgn__modal-backdrop {
  background: $modal-backdrop-bg;
  bottom: 0;
  left: 0;
  opacity: $modal-backdrop-opacity;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
}

.pgn__modal-content-container {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: .1px;
  position: relative;

  & > *:not(.pgn__modal-backdrop) {
    position: relative;
    z-index: 1;
  }

  h1,
  .h1,
  p {
    text-align: start;
  }
}

// Bootstrap modal styles

.modal.show {
  position: fixed;
  background-color: transparent;
  max-height: 100%;
  width: 100%;

  &:focus {
    .modal-dialog {
      box-shadow: $btn-focus-box-shadow;
    }
  }
}

.modal.is-ie11 {
  // fix browser that likes to do things its own way
  overflow-y: scroll;
  height: auto;

  .modal-content {
    height: auto;
    max-height: none;
  }
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, .3);

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.show { opacity: 1; }
}

.modal-dialog {
  height: 100%;
  margin: auto;
  padding: calc($spacer / 2);

  @media (min-width: map-get($grid-breakpoints, "sm")) {
    padding: $spacer;
  }
}

.modal-content {
  max-height: calc(100vh - (#{$spacer} * 2));

  &:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

.modal-header {
  flex: 0 0 auto;
}

.modal-body {
  overflow: auto;
}

.modal-footer {
  flex: 0 0 auto;
}

.pgn__modal-popup__arrow {
  position: absolute;
  width: 1rem;
  height: .5rem;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
    border-width: .5rem .5rem 0;
  }

  &::before {
    border-top-color: rgba($black, 20%);
  }
}

[data-popper-placement^="bottom-start"] .pgn__modal-popup__arrow-auto-start,
[data-popper-placement^="bottom-start"] .pgn__modal-popup__arrow-auto-end,
.pgn__modal-popup__arrow-bottom-start {
  transform: rotate(180deg);
  top: -.5rem;
  left: 33%;
}

[data-popper-placement^="bottom"] .pgn__modal-popup__arrow-auto,
.pgn__modal-popup__arrow-bottom {
  transform: rotate(180deg);
  top: -.5rem;
  left: 48%;
}

[data-popper-placement^="top-start"] .pgn__modal-popup__arrow-auto-start,
[data-popper-placement^="top-start"] .pgn__modal-popup__arrow-auto-end,
.pgn__modal-popup__arrow-top-start {
  bottom: -.5rem;
  left: 33%;
}

[data-popper-placement^="top"] .pgn__modal-popup__arrow-auto,
.pgn__modal-popup__arrow-top {
  bottom: -.5rem;
  left: 48%;
}

.pgn__modal-popup__arrow::after,
.pgn__modal-popup__arrow-top-start::after,
.pgn__modal-popup__arrow-top::after {
  bottom: $border-width;
  border-top-color: $white;
}

[data-popper-placement^="right"] .pgn__modal-popup__arrow-auto,
[data-popper-placement^="right-start"] .pgn__modal-popup__arrow-auto-start,
[data-popper-placement^="right-start"] .pgn__modal-popup__arrow-auto-end,
.pgn__modal-popup__arrow-right-start,
.pgn__modal-popup__arrow-right {
  left: -1.25rem;
  top: 50%;
  transform: rotate(90deg);
  margin: 0 .5rem;
}

[data-popper-placement^="left"],
.pgn__modal-popup__arrow-left {
  right: -.75rem;
  top: 50%;
  transform: rotate(-90deg);
}

[data-popper-placement^="left-end"] .pgn__modal-popup__arrow-auto-start,
[data-popper-placement^="left-end"] .pgn__modal-popup__arrow-auto-end,
.pgn__modal-popup__arrow-left-end {
  top: 77%;
  right: -.75rem;
  transform: rotate(-90deg);
}

[data-popper-placement^="right-end"] .pgn__modal-popup__arrow-auto-start,
[data-popper-placement^="right-end"] .pgn__modal-popup__arrow-auto-end,
.pgn__modal-popup__arrow-right-end {
  top: 77%;
  transform: rotate(90deg);
  left: -.75rem;
}

[data-popper-placement^="left-start"] .pgn__modal-popup__arrow-auto-start,
[data-popper-placement^="left-start"] .pgn__modal-popup__arrow-auto-end,
.pgn__modal-popup__arrow-left-start {
  top: 15%;
  right: -.75rem;
  transform: rotate(-90deg);
}

[data-popper-placement^="right-start"] .pgn__modal-popup__arrow-auto-start,
[data-popper-placement^="right-start"] .pgn__modal-popup__arrow-auto-end,
.pgn__modal-popup__arrow-right-start {
  top: 15%;
}

[data-popper-placement^="top-end"] .pgn__modal-popup__arrow-auto-start,
[data-popper-placement^="top-end"] .pgn__modal-popup__arrow-auto-end,
.pgn__modal-popup__arrow-top-end {
  left: 62%;
}

[data-popper-placement^="bottom-end"] .pgn__modal-popup__arrow-auto-start,
[data-popper-placement^="bottom-end"] .pgn__modal-popup__arrow-auto-end,
.pgn__modal-popup__arrow-bottom-end {
  top: -.5rem;
  left: 62%;
  transform: rotate(180deg);
}
