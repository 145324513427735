.pgn__stepper-header-step-list {
  list-style: none;
  padding: .25rem 0;
  display: flex;
  align-items: center;
  margin: 0;
  flex-grow: 1;
  justify-content: center;
}

.pgn__stepper-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  padding: .75rem 1rem;
  min-height: 5.13rem;
}

.pgn__stepper-header-step {
  display: flex;
  align-items: center;
  color: $primary;
  flex-shrink: 1;
  min-width: 0;

  .pgn__bubble {
    margin-inline-end: .5rem;
    flex-shrink: 0;
  }

  .pgn__stepper-header-step-title-description {
    min-width: 0;
  }

  .pgn__stepper-header-step-title {
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
  }

  .pgn__stepper-header-step-description {
    font-size: $x-small-font-size;
  }

  &.pgn__stepper-header-step-active ~ .pgn__stepper-header-step {
    color: $gray-500;
  }

  &.pgn__stepper-header-step-has-error {
    .pgn__bubble {
      background: transparent;
      box-shadow: inset 0 0 0 3px $danger;

      * {
        color: $danger;
      }
    }

    .pgn__stepper-header-step-description {
      color: $danger;
    }
  }
}

.pgn__stepper-header-line {
  display: block;
  height: 1px;
  background: $light;
  flex-basis: 80px;
  margin: 0 .5rem;
}
