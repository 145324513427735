// Pagination

$pagination-padding-y:              .625rem !default;
$pagination-padding-x:              1rem !default;
$pagination-padding-y-sm:           .8rem !default;
$pagination-padding-x-sm:           .6rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-margin-x:               .5rem !default;
$pagination-margin-y:               .5rem !default;
$pagination-line-height:            1.5rem !default;
$pagination-font-size-sm:           .875rem !default;

$pagination-icon-size:              1.375rem !default;
$pagination-icon-size-sm:           1rem !default;
$pagination-icon-width:             2.25rem !default;
$pagination-icon-height:            2.25rem !default;
$pagination-padding-icon:           .5rem !default;
$pagination-toggle-border:          .3125rem !default;
$pagination-toggle-border-sm:       .25rem !default;
$pagination-secondary-height:       2.75rem !default;
$pagination-secondary-height-sm:    2.25rem !default;

$pagination-color:                  $link-color !default;
$pagination-color-inverse:          $white !default;
$pagination-bg:                     $white !default;
$pagination-border-width:           $border-width !default;
$pagination-border-color:           theme-color("gray", "border") !default;

$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
$pagination-focus-outline:          0 !default;
$pagination-focus-border-width:     .125rem !default;
$pagination-focus-color:            $primary-500 !default;
$pagination-focus-color-text:       $black !default;

$pagination-hover-color:            $link-hover-color !default;
$pagination-hover-bg:               theme-color("gray", "background") !default;
$pagination-hover-border-color:     theme-color("gray", "border") !default;

$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         theme-color("gray", "light-text") !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  theme-color("gray", "disabled-border") !default;

$pagination-border-radius-sm:       $border-radius-sm !default;
$pagination-border-radius-lg:       $border-radius-lg !default;

$pagination-reduced-dropdown-max-height: 60vh !default;
$pagination-reduced-dropdown-min-width: 6rem !default;
