// Progress bars

$progress-height:                   1rem !default;
$annotated-progress-height:         .3125rem !default;
$progress-font-size:                $font-size-base * .75 !default;
$progress-bg:                       transparent !default;
$progress-border-radius:            0 !default;
$progress-box-shadow:               none !default;
$progress-bar-color:                $white !default;
$progress-bar-bg:                   theme-color("accent-a") !default;
$annotated-progress-bar-bg:         theme-color("dark") !default;
$progress-bar-animation-timing:     1s linear infinite !default;
$progress-bar-transition:           width .6s ease !default;
$progress-bar-border-width:         1px !default;
$progress-bar-border-color:         $gray-500 !default;
$progress-threshold-circle:         .5625rem !default;
$progress-hint-annotation-gap:      .5rem !default;
$progress-colors: (
  "dark":    $primary-500,
  "success": $success-500,
  "error":   $danger-500,
  "warning": $accent-b,
) !default;
