@import "variables";

.pgn__selectable_box-set {
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: $selectable-box-space;

  @for $i from $min-cols-number through $max-cols-number {
    &.pgn__selectable_box-set--#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  }

  & > * + * {
    margin: 0;
  }
}

.pgn__selectable_box {
  position: relative;
  height: 100%;
  padding: $selectable-box-padding;
  box-shadow: $level-1-box-shadow;
  border-radius: $selectable-box-border-radius;
  text-align: start;

  &:focus-visible {
    outline: 1px solid $primary-700;
  }

  .pgn__form-radio,
  .pgn__form-checkbox {
    position: absolute;
    top: $selectable-box-padding;
    inset-inline-end: $selectable-box-padding;

    input {
      margin-inline-end: 0;
    }
  }

  * {
    pointer-events: none;
  }
}

.pgn__selectable_box-active {
  outline: 2px solid $primary-500;
}

.pgn__selectable_box-invalid {
  outline: 2px solid $danger-300;
}
