$annotation-padding:               .5rem !default;
$annotation-box-shadow:            drop-shadow(0 2px 4px rgba(0, 0, 0, .15))
  drop-shadow(0 2px 8px rgba(0, 0, 0, .15)) !default;
$annotation-border-radius:         .25rem !default;
$annotation-max-width:             18.75rem !default;
$annotation-font-size:             $font-size-sm !default;
$annotation-line-height:           $line-height-sm !default;

$annotation-variants: (
  "success": ( "background-color": $success, "color": $white ),
  "warning": ( "background-color": $accent-b, "color": $black ),
  "error":   ( "background-color": $danger, "color": $white ),
  "light":   ( "background-color": $white, "color": $primary-500 ),
  "dark":    ( "background-color": $dark, "color": $white ),
) !default;

$annotation-arrow-side-margin:     .25rem !default;
$annotation-arrow-border-width:    .5rem !default;
