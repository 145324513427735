@import "variables";
@import "~bootstrap/scss/toasts";

.toast {
  background-color: $toast-background-color;
  box-shadow: $toast-box-shadow;
  margin: 0;
  padding: 1rem;
  position: relative;
  border-radius: $toast-border-radius;
  z-index: 2;

  &.show {
    display: flex;
    flex-direction: column;
  }

  .toast-header-btn-container {
    margin: -.25rem -.5rem;
    align-self: flex-start;
  }

  .btn {
    margin-top: .75rem;
    align-self: flex-start;
  }

  .toast-header {
    align-items: center;
    border-bottom: 0;
    justify-content: space-between;
    padding: 0;

    p {
      font-size: $small-font-size;
      margin: 0;
      padding-right: .75rem;
    }

    & + .btn {
      margin-top: 1rem;
    }
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }

  @media only screen and (min-width: 768px) {
    min-width: $toast-max-width;
    max-width: $toast-max-width;
  }
}
