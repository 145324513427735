@import "../Card/variables"; // TODO: Remove this dependency
@import "variables";

// TODO: Remove this dependency
@import "../Card/Card";

.collapsible-card {
  // stylelint-disable-next-line
  @extend .card;

  border-radius: 0;

  .collapsible-trigger {
    padding: $collapsible-card-spacer-y $collapsible-card-spacer-x;
    border-radius: $card-inner-border-radius;
    border-bottom: $card-border-width solid transparent;
    transition: border-color 100ms ease 150ms;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;

    & > * {
      margin-bottom: 0;
      margin-top: 0;
    }

    &[aria-expanded="true"] {
      border-radius: $card-inner-border-radius $card-inner-border-radius 0 0;
      border-color: $card-border-color;
      transition: none;
      text-align: start;
    }
  }

  .collapsible-body {
    // stylelint-disable-next-line
    @extend .card-body;

    padding: $collapsible-card-spacer-y $collapsible-card-spacer-x;
    padding-left: $collapsible-card-body-spacer-left;
    text-align: start;

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .collapsible-icon {
    margin-inline-start: $collapsible-card-spacer-icon;
  }
}

.collapsible-card-lg {
  // stylelint-disable-next-line
  @extend .collapsible-card;

  .collapsible-trigger {
    padding: $collapsible-card-spacer-y-lg $collapsible-card-spacer-x-lg;
  }

  .collapsible-body {
    padding: $collapsible-card-spacer-y-lg $collapsible-card-spacer-x-lg;
  }
}

.collapsible-basic {
  .collapsible-trigger {
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: underline;
    color: theme-color("primary", "default");
    padding: $collapsible-basic-spacer-y $collapsible-basic-spacer-x;
  }

  .collapsible-body {
    padding: $collapsible-basic-spacer-y $collapsible-basic-spacer-x;
    text-align: start;
  }

  .collapsible-icon {
    margin-left: $collapsible-basic-spacer-icon;
  }
}
