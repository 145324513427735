// Breadcrumbs

$breadcrumb-font-size:              null !default;

$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              1rem !default;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-margin-left:           .5rem !default;

$breadcrumb-border-focus-axis-x:    .25rem !default;
$breadcrumb-border-focus-axis-y:    .5rem !default;

$breadcrumb-border-focus-width:     .0625rem !default;

$breadcrumb-bg:                     $gray-200 !default;
$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-active-color:           $gray-500 !default;
$breadcrumb-inverse-active:         $light-500 !default;
$breadcrumb-inverse-spacer:         $light-700 !default;
$breadcrumb-color:                  $primary-500 !default;
$breadcrumb-inverse-color:          $white !default;
$breadcrumb-divider:                "/" !default;

$breadcrumb-border-radius:          $border-radius !default;
$breadcrumb-focus-border-radius:          .125rem !default;
