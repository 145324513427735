$input-padding-y:                       $input-btn-padding-y !default;
$input-padding-x:                       $input-btn-padding-x !default;
$input-font-family:                     $input-btn-font-family !default;
$input-font-size:                       $input-btn-font-size !default;
$input-font-weight:                     $font-weight-base !default;
$input-line-height:                     $input-btn-line-height !default;

$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
$input-font-size-sm:                    $input-btn-font-size-sm !default;
$input-line-height-sm:                  $input-btn-line-height-sm !default;

$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                    $input-btn-font-size-lg !default;
$input-line-height-lg:                  $input-btn-line-height-lg !default;

$input-bg:                              $white !default;
$input-disabled-bg:                     theme-color("gray", "background") !default;

$input-color:                           theme-color("gray", "text") !default;
$input-border-color:                    $gray-500 !default;
$input-border-width:                    $input-btn-border-width !default;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius:                   $border-radius !default;
$input-border-radius-lg:                $border-radius-lg !default;
$input-border-radius-sm:                $border-radius-sm !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              $component-active-bg !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     1px !default;
$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color:               theme-color("gray", "light-text") !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    calc(#{$input-line-height * 1em} + #{$input-padding-y * 2}) !default;
$input-height-inner-half:               calc(#{$input-line-height * .5em} + #{$input-padding-y}) !default;
$input-height-inner-quarter:            calc(#{$input-line-height * .25em} + #{calc($input-padding-y / 2)}) !default;

$input-height:                          calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
) !default;
$input-height-sm:                       calc(
  #{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border}
) !default;
$input-height-lg:                       calc(
  #{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border}
) !default;

$input-hover-width:                     1px !default;
$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-text-margin-top:                  .25rem !default;

$form-check-input-gutter:               1.25rem !default;
$form-check-input-margin-y:             .3rem !default;
$form-check-input-margin-x:             .25rem !default;

$form-check-inline-margin-x:            .75rem !default;
$form-check-inline-input-margin-x:      .3125rem !default;

$form-check-position-axis:              .375rem !default;
$form-check-focus-border-radius:        .0625rem !default;
$form-check-border-width:               .125rem !default;

$form-grid-gutter-width:                10px !default;
$form-group-margin-bottom:              1rem !default;

$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  theme-color("gray", "background") !default;
$input-group-addon-border-color:        $input-border-color !default;

$custom-forms-transition:               background-color .15s ease-in-out,
  border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$custom-control-gutter:                 .5rem !default;
$custom-control-spacer-x:               1rem !default;
$custom-control-cursor:                 null !default;

$custom-control-indicator-size:         1.25rem !default;
$custom-control-indicator-bg:           $input-bg !default;

$custom-control-indicator-bg-size:      100% !default;
$custom-control-indicator-box-shadow:   $input-box-shadow !default;
$custom-control-indicator-border-color: $gray-700 !default;
$custom-control-indicator-border-width: 2px !default;

$custom-control-label-color:            null !default;
$custom-control-indicator-disabled-bg:          $input-disabled-bg !default;
$custom-control-label-disabled-color:           theme-color("gray", "light-text") !default;

$custom-control-indicator-checked-color:        $component-active-bg !default;
$custom-control-indicator-checked-bg:           $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg:  rgba($primary, .5) !default;
$custom-control-indicator-checked-box-shadow:   none !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

$custom-control-indicator-focus-box-shadow:     0 0 0 4px rgba(0, 0, 0, .1) !default;
$custom-control-indicator-focus-border-color:   $input-focus-border-color !default;

$custom-control-indicator-active-color:         $component-active-color !default;
$custom-control-indicator-active-bg:            $component-active-bg !default;
$custom-control-indicator-active-box-shadow:    none !default;
$custom-control-indicator-active-border-color:  $custom-control-indicator-active-bg !default;
$custom-checkbox-indicator-border-radius:       0 !default;
$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M21 3H3V21H21V3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z' fill='#{$custom-control-indicator-checked-color}'/></svg>"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg:           $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate:         str-replace(url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M21 3H3V21H21V3ZM17 13H7V11H17V13Z' fill='#{$custom-checkbox-indicator-indeterminate-color}'/></svg>"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow:   none !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

$custom-radio-indicator-border-radius:          50% !default;
$custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-bg}'/%3e%3c/svg%3e"), "#", "%23") !default;

$custom-switch-indicator-icon-off:              str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-bg}'/%3e%3c/svg%3e"), "#", "%23") !default;
$custom-switch-indicator-icon-on:               str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-active-color}'/%3e%3c/svg%3e"), "#", "%23") !default;
$custom-switch-width:                           $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius:         calc($custom-control-indicator-size / 2) !default;
$custom-switch-indicator-size:                  calc(
  #{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4}
) !default;
$custom-switch-indicator-checked-bg:            theme-color("success") !default;

$custom-select-padding-y:           $input-padding-y !default;
$custom-select-padding-x:           $input-padding-x !default;
$custom-select-font-family:         $input-font-family !default;
$custom-select-font-size:           $input-font-size !default;
$custom-select-height:              $input-height !default;

// Extra padding to account for the presence of the background-image based indicator
$custom-select-indicator-padding:   1rem !default;
$custom-select-font-weight:         $input-font-weight !default;
$custom-select-line-height:         $input-line-height !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      theme-color("gray", "light-text") !default;
$custom-select-bg:                  $input-bg !default;
$custom-select-disabled-bg:         theme-color("gray", "background") !default;
$custom-select-bg-size:             24px 24px !default; // In pixels because image dimensions
$custom-select-indicator-color:     theme-color("gray", "hover") !default;

$custom-select-indicator:           str-replace(url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984L16.59 8.58984Z" fill="#{$custom-select-indicator-color}"/></svg>'), "#", "%23") !default;

// Used so we can have multiple background elements (e.g., arrow and feedback icon)
$custom-select-background:          $custom-select-indicator no-repeat right
  $input-padding-y center / $custom-select-bg-size !default;

$custom-select-feedback-icon-padding-right: calc(
  (1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x + $custom-select-indicator-padding}
) !default;
$custom-select-feedback-icon-position:      center right
  ($custom-select-padding-x + $custom-select-indicator-padding) !default;
$custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half !default;

$custom-select-border-width:        $input-border-width !default;
$custom-select-border-color:        $input-border-color !default;
$custom-select-border-radius:       $border-radius !default;
$custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075) !default;

$custom-select-focus-border-color:  $input-focus-border-color !default;
$custom-select-focus-width:         $input-focus-width !default;
$custom-select-focus-box-shadow:    $input-btn-focus-box-shadow !default;

$custom-select-padding-y-sm:        $input-padding-y-sm !default;
$custom-select-padding-x-sm:        $input-padding-x-sm !default;
$custom-select-font-size-sm:        $input-font-size-sm !default;
$custom-select-height-sm:           $input-height-sm !default;

$custom-select-padding-y-lg:        $input-padding-y-lg !default;
$custom-select-padding-x-lg:        $input-padding-x-lg !default;
$custom-select-font-size-lg:        $input-font-size-lg !default;
$custom-select-height-lg:           $input-height-lg !default;

$custom-range-track-width:          100% !default;
$custom-range-track-height:         .5rem !default;
$custom-range-track-cursor:         pointer !default;
$custom-range-track-bg:             theme-color("gray", "graphic") !default;
$custom-range-track-border-radius:  1rem !default;
$custom-range-track-box-shadow:     inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-range-thumb-width:                   1rem !default;
$custom-range-thumb-height:                  $custom-range-thumb-width !default;
$custom-range-thumb-bg:                      $component-active-bg !default;
$custom-range-thumb-border:                  0 !default;
$custom-range-thumb-border-radius:           1rem !default;
$custom-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) !default;
$custom-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow !default;
$custom-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg:               lighten($component-active-bg, 35%) !default;
$custom-range-thumb-disabled-bg:             theme-color("gray", "default") !default;

$custom-file-height:                $input-height !default;
$custom-file-height-inner:          $input-height-inner !default;
$custom-file-focus-border-color:    $input-focus-border-color !default;
$custom-file-focus-box-shadow:      $input-focus-box-shadow !default;
$custom-file-disabled-bg:           $input-disabled-bg !default;

$custom-file-padding-y:             $input-padding-y !default;
$custom-file-padding-x:             $input-padding-x !default;
$custom-file-line-height:           $input-line-height !default;
$custom-file-font-family:           $input-font-family !default;
$custom-file-font-weight:           $input-font-weight !default;
$custom-file-color:                 $input-color !default;
$custom-file-bg:                    $input-bg !default;
$custom-file-border-width:          $input-border-width !default;
$custom-file-border-color:          $input-border-color !default;
$custom-file-border-radius:         $input-border-radius !default;
$custom-file-box-shadow:            $input-box-shadow !default;
$custom-file-button-color:          $custom-file-color !default;
$custom-file-button-bg:             $input-group-addon-bg !default;
$custom-file-text: (
  en: "Browse"
) !default;

// Form validation

$form-feedback-margin-top:          $form-text-margin-top !default;
$form-feedback-font-size:           $small-font-size !default;
$form-feedback-valid-color:         theme-color("success") !default;
$form-feedback-invalid-color:       theme-color("danger") !default;

$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
$form-feedback-icon-valid:          str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"), "#", "%23") !default;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23") !default;

$form-validation-states: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid
    ),
    "invalid": (
      "color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:     .25rem !default;
$form-feedback-tooltip-padding-x:     .5rem !default;
$form-feedback-tooltip-font-size:     $font-size-sm !default;
$form-feedback-tooltip-line-height:   $line-height-base !default;
$form-feedback-tooltip-opacity:       .9 !default;
$form-feedback-tooltip-border-radius: $border-radius !default;
