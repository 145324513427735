@import "variables";

.pgn__searchfield {
  transition: $input-transition;
  border: $search-border-width solid $search-border-color;

  .btn:focus-visible {
    outline: none;
    position: relative;
    transition: $input-transition;

    &::after {
      content: "";
      border: $search-border-focus-width double $search-border-focus-color;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &.disabled,
  &:disabled {
    opacity: $search-disabled-opacity;
    pointer-events: none;
  }

  &.has-focus:not(.pgn__searchfield--external) {
    position: relative;

    &::after {
      content: "";
      border: $search-border-focus-width double $search-border-focus-color;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .pgn__searchfield_wrapper {
      box-shadow: 0 0 0 $search-border-width $search-border-color-interaction;
    }
  }

  &:hover,
  &:active {
    border-color: $search-border-color-interaction;
  }

  .form-control {
    border: none;
    border-radius: $search-border-radius;
    height: $input-height-search;

    &:focus {
      box-shadow: none;
    }

    // clear browser 'x' from Internet Explorer
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    // clear browser 'x' from Chrome
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    .form-control:hover,
    .form-control:focus,
    .form-control:active {
      border: none;
    }
  }

  label.has-label-text {
    padding-inline-start: .75rem;
  }

  &.pgn__searchfield--external {
    border: none;

    .btn-primary {
      background: map-get($search-btn-variants, "light");
    }

    .btn-brand {
      background: map-get($search-btn-variants, "dark");
    }

    &.has-focus {
      box-shadow: none;

      .pgn__searchfield_wrapper {
        position: relative;

        &::after {
          content: "";
          border: $search-border-focus-width double $search-border-focus-color;
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      .btn-primary {
        background: map-get($search-btn-variants, "light");
      }

      .btn-brand {
        background: map-get($search-btn-variants, "dark");
      }
    }
  }

  .pgn__searchfield_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    border: $search-border-width solid $search-border-color;

    &:hover,
    &:active {
      border-color: $search-border-color-interaction;
    }
  }
}

.pgn__searchfield__button.btn[type="submit"] {
  border-radius: 0;
  margin-inline-start: $search-button-margin;
}
